<template>
    <div>
        <mdb-card class="mb-2">
            <mdb-card-header color="default">查詢</mdb-card-header>
            <mdb-card-body>
                <mdb-row>
                    <mdb-col md="3">
                        <mdb-select label="人員"
                                    placeholder="選擇人員"
                                    @getValue="val=>{search.id_admin=val; }"
                                    v-model="adminList"></mdb-select>
                    </mdb-col>
                    <mdb-col md="3">
                        <datepicker label="日期(起)"
                                    v-model="search.day_start"
                                    :max="new Date()" />
                    </mdb-col>
                    <mdb-col md="3">
                        <datepicker label="日期(迄)"
                                    v-model="search.day_end"
                                    :min="search.day_start"
                                    :max="new Date()" />
                    </mdb-col>
                    <mdb-col md="3">
                        <mdb-btn color="default"
                                 size="sm"
                                 class="mt-3"
                                 @click="data.nowPage=1;searchdata()">
                            <i class="fa fa-search"></i>
                            搜&nbsp;&nbsp;尋
                        </mdb-btn>
                    </mdb-col>
                </mdb-row>
            </mdb-card-body>
        </mdb-card>
        <mdb-card>
            <mdb-card-body>
                <table class="table table-striped rwd-table-dispersion">
                    <!--Table head-->
                    <thead>
                        <tr>
                            <th style="w">#</th>
                            <th>日期</th>
                            <th>員工</th>
                            <th>上班</th>
                            <th>下班</th>
                        </tr>
                    </thead>
                    <!--Table head-->
                    <!--Table body-->
                    <tbody>
                        <tr v-for="(p,pk) in data.punchList"
                            :key="`p_${pk}`">
                            <td data-title="#">{{pk+1}}</td>
                            <td data-title="日期">{{p.day}}</td>
                            <td data-title="員工">{{adminKeyList[p.id_admin].name}}</td>
                            <td data-title="上班">
                                {{p['start']}}<br>
                                {{storeKeyList[p.id_store_start]?storeKeyList[p.id_store_start].name:''}}
                            </td>
                            <td data-title="下班">
                                {{p['end']}}<br>
                                {{storeKeyList[p.id_store_end]?storeKeyList[p.id_store_end].name:''}}
                            </td>
                        </tr>
                        <tr>
                            <td colspan="5"
                                scope="row"
                                v-show="data.punchList.length==0">無資料</td>
                        </tr>
                    </tbody>
                    <!--Table body-->
                </table>
            </mdb-card-body>
        </mdb-card>
    </div>
</template>

<script>
import {
  mdbCard,
  mdbCardHeader,
  mdbCardBody,
  mdbRow,
  mdbCol,
  mdbBtn,
  mdbSelect,
} from "mdbvue";
import datepicker from "../../components/datepicker";

export default {
  props: { query: { default: {} } },
  components: {
    mdbCard,
    mdbCardHeader,
    mdbCardBody,
    mdbRow,
    mdbCol,
    mdbBtn,
    mdbSelect,
    datepicker,
  },
  data() {
    return {
      search: {
        id_admin: "",
        id_store: "",
        order_status: "",
        day_start: "",
        day_end: "",
        page: 1,
      },
      search_change: false,
      storeList: [],
      adminList: [],
      data: {
        punchList: [],
      },
    };
  },
  mounted() {
    let vue = this;
    // 把篩選的資料放到變數裡面
    for (let i in vue.search) {
      switch (i) {
        case "day_start":
        case "day_end":
          vue.search[i] = vue.query[i]
            ? vue.query[i]
            : vue.$moment().format("YYYY-MM-DD");
          break;
        default:
          vue.search[i] = vue.query[i] ? vue.query[i] : "";
          break;
      }
    }
    vue.search.page = vue.search.page != "" ? vue.search.page : 1;
    // 取出一些基本資料
    let T = true;
    if (T) {
      let query = [
        {
          name: "store",
          data: {
            active: {
              type: 0,
              value: 1,
            },
          },
          sort: {
            name: 0,
          },
        },
        {
          name: "admin",
          data: {
            password: {
              type: 1,
              value: "",
            },
          },
          sort: {
            name: 0,
          },
        },
      ];
      vue.$store
        .dispatch("get_form", {
          payload: {
            url: `data/get_data/?query=${JSON.stringify(query)}`,
          },
        })
        .then((res) => {
          vue.storeList = res.data.storeList;
          res.data.adminList.unshift({ id: "", name: "全部" });
          vue.adminList = res.data.adminList.map((item) => {
            item.text = item.name;
            item.value = item.id;
            item.selected = vue.search.id_admin == item.id;
            return item;
          });
          vue.getData();
        });
    }
  },
  watch: {
    search: {
      handler() {
        this.search_change = true;
      },
      deep: true,
    },
  },
  computed: {
    adminKeyList() {
      let output = {};
      this.adminList.forEach((item) => {
        output[item.id] = item;
      });
      return output;
    },
    storeKeyList() {
      let output = {};
      this.storeList.forEach((item) => {
        output[item.id] = item;
      });
      return output;
    },
  },
  methods: {
    // 搜尋資料
    searchdata() {
      let vue = this;
      if (vue.search_change) {
        let url = vue.$route.path + "?";
        for (let k in vue.search) {
          url += `${k}=${vue.search[k]}&`;
        }
        vue.$router.push(url);
      }
      vue.search_change = false;
      vue.getData();
    },
    getData() {
      let vue = this,
        query = [{ name: "punch", data: {}, sort: { day: 1 } }];
      if (vue.search.id_admin != "") {
        query[0].data["id_admin"] = { type: 0, value: vue.search.id_admin };
      }
      if (vue.search.day_start != "" && vue.search.day_end != "") {
        query[0].data["day"] = {
          type: 7,
          value: [vue.search.day_start, vue.search.day_end],
        };
      } else if (vue.search.day_start != "" && vue.search.day_end == "") {
        query[0].data["day"] = {
          type: 2,
          value: vue.search.day_start,
        };
      } else if (vue.search.day_start == "" && vue.search.day_end != "") {
        query[0].data["day"] = {
          type: 3,
          value: vue.search.day_end,
        };
      }
      vue.$store
        .dispatch("get_form", {
          payload: {
            url: `data/get_data/?query=${JSON.stringify(query)}`,
          },
        })
        .then((res) => {
          console.log(res);
          vue.data.punchList = res.data.punchList;
          // res.data.punchList.forEach((item, index) => {
          //   vue.$set(vue.data.punchList, index, item);
          // });
        });
    },
  },
};
</script>